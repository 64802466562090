export enum DownloadItemType {
  CSV,
}

export class DownloadedItem {
  itemType: DownloadItemType;
  id: any;
  token: string;
  url: string;
  fileName: string;
  blob: Blob;
  rawData: any;
  isDownloading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

